.nav {
  display: flex;

  &__item {
    border: $border-type-base transparent;
    border-radius: $border-radius-md;
    margin-left: $margin-sm;
    padding: $padding-xs $padding-sm;

    &.active {
      border-color: $primary-color;

      @include breakpoint(md) {
        border: none;
        padding: 0.25rem 0;
      }
    }

    @include breakpoint(lg) {
      font-size: 32px;
      line-height: 1;
    }
  }

  @include breakpoint(lg) {
    display: none;
  }

  &--expand {
    display: block;
  }

  &--toggle {
    cursor: pointer;
    position: fixed;
    right: 2rem;
    top: 2rem;
  }
}

.nav-icon {
  display: none;
  height: 2rem;
  position: relative;
  width: 3rem;

  @include breakpoint(lg) {
    display: block;
  }

  &--line {
    background-color: $primary-color;
    height: 1px;
    position: absolute;
    width: 100%;
    @include transform(rotate(0deg));
    @include transition(all 0.25s ease);
    @include position(absolute, $top: 0, $left: 0, $height: 1px, $width: 100%);

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2) {
      top: 12px;
    }
  }

  &--active {
    .nav-icon--line {
      background-color: white;

      &:nth-child(1) {
        @include transform(rotate(45deg));
        top: 12px;
      }

      &:nth-child(2) {
        @include transform(rotate(-45deg));
        top: 12px;
      }
    }
  }
}