.accordion {
  margin: $margin-lg $margin-no;


  @include breakpoint(md) {
    margin: $margin-sm $margin-no;
  }

  &__item {
    display: flex;
    gap: $gap-base;
    margin-bottom: $margin-md;
    scroll-margin: 10rem;

    @include breakpoint(md) {
      gap: 1rem;
      flex-direction: column;
      margin-bottom: $margin-xs;

      h2 {
        margin: 0;
      }
    }
  }

  &__col {
    flex: 1;

    img {
      border-radius: $border-radius-base;
      overflow: hidden;
    }

    @include breakpoint(md) {
      width: 100%;
    }
  }

  &__title {
    display: flex;

    .icon {
      cursor: pointer;
      display: block;
      margin-left: auto;
      height: 2rem;
      width: 2rem;

      &--plus {
        background-image: url("../../assets/icons/i-plus.svg");
      }

      &--minus {
        background-image: url("../../assets/icons/i-minus.svg");
      }

      @include breakpoint(lg) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}