.summary {
  display: flex;
  gap: $gap-base;
  margin: $margin-lg $margin-no;

  @include breakpoint(md) {
    flex-direction: column;
    gap: 0;
    margin: $margin-md $margin-no;
  }

  &__col {
    margin-left: auto;
    flex: 0 0 calc(50% - ($gap-base/2));

    @include breakpoint(md) {
      margin-left: 0;
    }
  }
}