.footer {
  background-color: $color-bg-base;
  position: relative;
  margin-top: 6rem;
  z-index: 10;

  @include breakpoint(md) {
    margin-top: 3rem;
    font-size: $font-size-md-m;
  }

  &__container {
    border-top: $border-type-base $border-color-base;
    display: flex;

    @include grid($gap-md);
    gap: $gap-md;
    padding: $padding-lg $padding-no;

    @include breakpoint(sm) {
      gap: 2rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    .copy {
      margin-top: 10rem;
    }

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 5rem;
      font-size: $font-size-xs-m;

      .copy {
        margin: 0;
      }
    }
  }

  &__col {
    flex: 1;

    &--flex {
      display: flex;

      @include breakpoint(md) {
        flex-direction: column;
      }
    }
  }

  .logo {
    margin-right: 3rem;
    width: 2.25rem;

    @include breakpoint(md) {
      margin-bottom: 2rem;
      width: 1.5rem;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}