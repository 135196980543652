.gallery {
  position: relative;

  &__item {
    .figure {
      border-radius: 0;
    }
  }
}

.carousel-section {
  &__container {
    margin: 0 -0.5rem;
  }

  &__item {
    overflow: hidden;
    padding: 0 0.5rem;
  }

  img {
    border-radius: 1rem;
    overflow: hidden;
  }

  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1rem;
    overflow: hidden;
  }
}

.slick-arrow {
  display: none !important;
}