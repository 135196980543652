.slider {
  @include position-sticky;
  align-items: center;
  background-size: cover !important;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  padding: $padding-no $padding-lg;

  h1 {
    text-align: center;
  }

  @include breakpoint(lg) {
    height: 50vh;
  }

  &--home {
    height: 120vh;

    @include breakpoint(lg) {
      height: 100vh;
    }
  }

  &__video {
    position: absolute;
    min-height: 120vh;
    object-fit: fill;
  }

  &__caption {
    margin: $margin-md $margin-no;
    position: relative;
    z-index: 4;

    @include breakpoint(md) {
      br {
        display: none;
      }
    }
  }

  &--relative {
    position: relative;
  }
}