// Transitions
@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  transition: $value;
}

// Absolute center
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-sticky {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
}

// Breakpoint
@mixin breakpoint($breakpoint-size) {
  $breakpoint-value: map-get($breakpoints, $breakpoint-size);

  @media only screen and (max-width: $breakpoint-value) {
    @content;
  }
}

@each $size,
$value in $p-sizes {
  .p-#{$size} {
    font-size: $value;
  }
}

@each $size,
$value in $p-sizes-mobile {
  @include breakpoint(lg) {
    .p-#{$size} {
      font-size: $value;
    }
  }
}

@each $size,
$value in $h-sizes {
  .h-#{$size} {
    font-size: $value;
    line-height: 1.2;
  }
}

@each $size,
$value in $h-sizes-mobile {
  .h-#{$size} {
    @include breakpoint(md) {
      font-size: $value;
    }
  }
}

// Gap
@mixin grid($gap) {
  display: flex;
  gap: $gap;

  &__col {
    flex: 1;
  }

  @include breakpoint(md) {
    flex-direction: column;
  }
}

// Transform
@mixin transform($value) {
  transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -webkit-transform: $value;
}

// Position
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null, $width: null, $height: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  height: $height;
  width: $width;
}