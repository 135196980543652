html {
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
  margin: 0;
}

button,
input {
  overflow: visible;
}

[hidden] {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

* {
  user-select: none;
}

*::selection,
*::-moz-selection {
  background: none;
}