html {
  background-color: $grey-bg !important;
}

body {
  background-color: $grey-bg !important;
  color: $font-color-base;
  font-family: $font-family-primary;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  line-height: $line-height-base;

  @include breakpoint(lg) {
    font-size: $font-size-xs-m;
  }
}

img {
  height: auto;
  display: block;
  width: 100%;
}

.main {
  //overflow: auto;
  background-color: $grey-bg;
  position: sticky;
  z-index: 15;
  border: 2px solid transparent;
}

.figure,
figure {
  border-radius: $border-radius-sm;
  overflow: hidden;
  position: relative;

  .video {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  &:hover {
    .video {
      display: block;
    }
  }
}

// Group link
.group-link {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: $margin-lg $margin-no;

  @include breakpoint(lg) {
    gap: $gap-xs;
    margin: $margin-md $margin-no;
  }

  &__item {
    margin-left: -$margin-sm;

    @include breakpoint(lg) {
      margin: $margin-no;
    }
  }
}

.products {
  scroll-margin: 10rem;
  margin-bottom: 8rem;

  @include breakpoint(md) {
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 3rem;

    @include breakpoint(md) {
      margin-bottom: 1rem;
    }
  }

  .product {
    margin-bottom: 2rem;

    .figure {
      margin-bottom: 2rem;
    }

    &__figure {
      position: relative;
      overflow: hidden;

      &--hover {
        @include transition(all 0.5s ease);
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
      }
    }

    &:hover,
    &:focus {
      .product__figure--hover {
        opacity: 1;
      }
    }



    .button {
      margin-top: 1rem;
    }

    @include breakpoint(md) {
      .figure {
        margin-bottom: 1rem;
      }

      margin-bottom: 1rem;

      .button {
        margin-top: 0;
      }
    }
  }
}

.imgs {
  border-radius: $border-radius-sm;
  height: 12rem;
  background-color: white;
  width: 100%;
}

.body-fixed {
  position: relative;
  overflow: hidden;
}

body {
  @include breakpoint(lg) {
    padding-top: 5rem;
  }
}


.loading {
  display: none;
}

.contact {

  @include breakpoint(md) {
    margin-top: 1rem;

    .grid {
      gap: 1rem;
    }
  }

  p {
    margin: 0;
  }

  a {
    border-bottom: 1px solid $primary-color;
    ;
  }
}