// Colors
$color-green: #57773d;
$color-grey: #f3f1f0;

$primary-color: $color-green;

$grey-bg: $color-grey;

$color-bg-base: $grey-bg;

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500;600&display=swap");

$font-family-alegreya: "Alegreya Sans";
$font-family-primary: $font-family-alegreya, sans-serif;

// --------------------------------------------------------
//  Fonts size
// --------------------------------------------------------
$font-size-xs: 1.375rem; //44
$font-size-sm: 1.875rem; // 44

$font-size-md: 2.5rem; // 40
$font-size-lg: 3.125rem; // 50
$font-size-xl: 5rem; // 120

$font-size-xs-m: 18px; //22
$font-size-sm-m: 20px; // 30

$font-size-md-m: 22px; // 60
$font-size-lg-m: 26px; // 70
$font-size-xl-m: 38px; // 100

$font-size-base: $font-size-xs;

$h-sizes: (
  small: $font-size-md,
  medium: $font-size-lg,
  large: $font-size-xl,
);

$h-sizes-mobile: (
  small: $font-size-md-m,
  medium: $font-size-lg-m,
  large: $font-size-xl-m,
);

$p-sizes: (
  small: $font-size-xs,
  medium: $font-size-sm,
);

$p-sizes-mobile: (
  small: $font-size-xs-m,
  medium: $font-size-sm-m,
);

// --------------------------------------------------------
//  Fonts
// --------------------------------------------------------

$font-weight-normal: 400;
$font-weight-bold: 500;

$font-weight-base: $font-weight-normal;

$font-color-base: $primary-color;

$heading-font-size-base: $font-size-md;
$heading-font-size-weight: $font-weight-base;

$line-height-base: 1.25;

// --------------------------------------------------------
//  Border
// --------------------------------------------------------
$border-type-base: 1px solid;
$border-color-base: $primary-color;

// --------------------------------------------------------
//  Border Radius
// --------------------------------------------------------
$border-radius-sm: 1rem;
$border-radius-md: 1.5rem;
$border-radius-lg: 2rem;

$border-radius-base: $border-radius-md;

// --------------------------------------------------------
//  Breakpoints
// --------------------------------------------------------
$break-xs-min: 25rem; // 420px
$break-sm-min: 38rem; // 600px
$break-md-min: 48rem; // 768px
$break-lg-min: 64rem; // 1024px

$breakpoints: (
  xs: $break-xs-min,
  sm: $break-sm-min,
  md: $break-md-min,
  lg: $break-lg-min,
);

// --------------------------------------------------------
//  Gap
// --------------------------------------------------------
$gap-xs: 1rem;
$gap-sm: 2rem;
$gap-md: 4rem;
$gap-lg: 6rem;
$gap-no: 0;

$gap-base: $gap-sm;

// --------------------------------------------------------
//  Margins
// --------------------------------------------------------
$margin-xs: 1rem;
$margin-sm: 2rem;
$margin-md: 3rem;
$margin-lg: 6rem;

$margin-no: 0;

// --------------------------------------------------------
//  Padding
// --------------------------------------------------------
$padding-xs: 0.5rem;
$padding-sm: 1rem;
$padding-md: 1.5rem;
$padding-lg: 2rem;
$padding-no: 0;

$page-max-width: 1300px;