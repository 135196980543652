* {
    color: $font-color-base;
}

h1,
h2,
h3 {
    font-weight: $heading-font-size-weight;
    margin-bottom: 1rem;
}

a {
    text-decoration: none;
}

p {
    margin-bottom: 1rem;
    line-height: 1.25;
}

.text-white {
    color: white;
}

strong {
    font-weight: 600;
}