.header {
  padding: 3.75rem 0;
  @include position-sticky;
  z-index: 20;
  @include transition(all 0.5s ease);

  &__bg {
    transform: translateX(100%);
    @include transition(all 0.5s ease);
    background: $primary-color;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    position: fixed;
    //border: 2px solid red;
  }

  &--overlay {
    .logo {
      background: white;
    }

    .nav__item {
      color: white;

      &.active {
        border-color: white;
      }
    }

    @include breakpoint(lg) {
      .nav-icon--line {
        background-color: white;
      }
    }
  }

  &__footer {
    display: none;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  @include breakpoint(lg) {
    position: fixed;
    height: 5rem;
    padding: 1rem 0;

    &__container {
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  &--expand {
    height: 100vh;
    position: fixed;

    .logo {
      background: white;
    }

    .nav {
      margin: 10vh 0;
      display: flex !important;
      flex-direction: column;

      &__item {
        color: white;
        margin: 0 0 1rem 0;
        padding: 0;
      }

      &--toggle {
        color: white;
      }
    }

    .header__bg {
      transform: translateX(0);
    }

    .header__footer {
      margin-bottom: 0;
      margin-top: 0;
      width: 100%;
      border-top: 1px solid white;
      padding: 1rem 0 0;
      display: block;

      * {
        color: white;
      }

      a {
        display: block;
      }

      &--end {
        margin-top: 1rem;
      }
    }
  }
}

.nav {
  color: inherit;
}

.logo {
  height: 3rem;
  width: 10rem;
  mask: url("../../assets/Bambu-logo.svg") no-repeat 50% 50%;
  background-color: $primary-color;
  text-indent: -99999px;
  z-index: -1;

  &--imago {
    mask: url("../../assets/Bambu-imago.svg") no-repeat 50% 50%;
  }

  @include breakpoint(lg) {
    height: 3rem;
    width: 7rem;
    z-index: 99;
  }
}