.article {
  display: flex;
  gap: $gap-base;
  margin-bottom: $margin-lg;

  @include breakpoint(md) {
    gap: $gap-no;
    flex-direction: column;
    margin-bottom: $margin-md;
  }

  &__summary {
    margin: $margin-sm $margin-no;

    @include breakpoint(md) {
      margin: $margin-xs $margin-no;
    }

    p {
      a {
        text-decoration: underline;
      }
    }
  }

  &__figure {
    border-radius: $border-radius-base;
    position: relative;
    overflow: hidden;

    &--hover {
      @include transition(all 0.5s ease);
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }

    &:hover,
    &:focus {
      .article__figure--hover {
        opacity: 1;
      }
    }
  }

  &__video {
    position: absolute;
    object-fit: cover;
    height: 100%;
  }

  &__col {
    flex: 1;
  }
}