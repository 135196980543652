.container {
  margin: 0 auto;
  max-width: 90rem;
  padding: $padding-no 4rem;

  @include breakpoint(lg) {
    padding: 0 2rem;
  }
}

.grid {
  @include grid($gap-sm);

  &--col-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .grid__col {
      width: 100%;
    }

    @include breakpoint(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(md) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}